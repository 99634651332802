import React from 'react';
import './Tokenomics.styles.scss';

const Tokenomics = () => {
  return (
    <section className="tokenomics" id="tokenomics">
      <div className="tokenomics__container">
        <h2>Tokenomics</h2>
        <div className="tokenomics__content">
          <div className="tokenomics__section">
            <h3>Token Distribution</h3>
            <ul>
              <li>Presale: 20%</li>
              <li>Crowdfunded Distribution: 65%</li>
              <li>Team: 15%</li>
            </ul>
          </div>
          <div className="tokenomics__section">
            <h3>Token Details</h3>
            <ul>
              <li>Token Name: $PIPE</li>
              <li>Total Supply: 420,690,000,000</li>
              <li>Initial Price: UNKNOWN</li>
              <li>Renounced contract ownership</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tokenomics;
