// src/components/hero/Hero.jsx
import React from 'react';
import ActionButton from '../action-button/ActionButton'
import './Hero.styles.scss';

const actions = [
  {
    title: 'Claim',
    href: "https://t.me/pipe1096_bot",
    image: '/images/claim.jpeg',
    imageAlt: 'Claim'
  },
  {
    title: 'Community',
    href: "https://t.me/+nc0HmmsEOAhmOTM0",
    image: '/images/join.jpeg',
    imageAlt: 'Community'
  },
  {
    title: 'Whitepaper',
    href: "/pIPe_whitepaper.pdf",
    image: '/images/whitepaper.jpeg',
    imageAlt: 'Whitepaper'
  },
  {
    title: 'Stake pIPe',
    href: "https://pipe-staking.vercel.app/",
    image: '/images/pIPe_LFG_small.jpg',
    imageAlt: 'Stake pIPe'
  },
];

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero__container">
        <h1>Welcome to pIPe Coin</h1>
        <p>
          PIPE is the ultimate meme coin for degens, attention-assets traders,
          content creators, and IP enthusiasts.
        </p>
        <div className="hero__buttons">
          {actions.map((action, index) => (
            <ActionButton key={index} {...action} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Hero;